export
default {
        common:
        {
            langYn:
            'Tiếng Việt',
            langEn: 'English',
            langJa: '日本語',
            langFt: '繁體中文',
            langKr: '한국인'

        },
        home: {
            h1: "Digital Cryptocurrency Bank",
            h1B: "MetaMask Cryptocurrency Bank",
            h1C: "DeFi Wallet Cryptocurrency",
            h1D: "Coinbase暗号通貨銀行",
            h1E: "MetaMask貯蓄銀行",
            h1F: "OneKeyデジタル通貨銀行",
            h2: "Invite friends",
            h3: "Bank account number",
            h4: "Participate in savings",
            h4B: "暗号通貨預金",
            h4C: "デジタル通貨預金",
            h5: "Selected Financial",
            h5B: "秒契約取引",
            h6: "User Pool",
            h7: "Account",
            h8: "Account",
            h9: "valid node",
            h10: "Participant",
            h11: "profit",
            h12: "Profit Record",
            h13: "Address",
            h14: "number",
            h15: "FAQ",
            h16: "How do I join",
            h17: "Dear users, if you want to participate in USDT wallet savings, you need to strictly implement the platform's decisions on the premise of abiding by the platform user code, and accept the supervision of the platform's risk department. After successfully opening an account, you will automatically be granted deposit permission. Welcome. Thank you for joining.",
            h17B: "ーザーの皆様,USDT ウォレットの貯蓄に参加したい場合は,プラットフォームのユーザー ルールを遵守することを前提として,プラットフォームの決定を厳密に実施し,プラットフォームのリスク部門の監督を受け入れる必要があります。自動的に預金権限を取得します. 参加へようこそ. ありがとうございます。",
            h18: "How do I withdraw money?",
            h19: "The income you generate every day will be automatically deposited into your account balance. After the USDT withdrawal is successful, it will be automatically added to your USDT wallet account. For Vietnamese Dong withdrawals, please contact the online customer service center. Thank you for your participation and support.",
            h19B: "毎日発生する収益は,自動的にアカウントの残高に入金します.USDT の引き出しが成功すると,USDT ウォレット アカウントに自動的に追加します.日本円の引き出しについては,オンライン カスタマー サービス センターに連絡して処理してください。",
            h20: "How to calculate income?",
            h21: "When you join successfully, the savings contract will start and the income will start to be calculated.",
            h21B: "正常に加入すると,貯蓄契約が収入の計算を開始します。",
            h22: "Where do savings income come from?",
            h23: "Once the user participates in savings, the savings contract takes effect, and the user can receive four interest settlements every day, at 0:00, 6:00, 12:00, and 18:00. After the savings contract takes effect, the user's funds are in custody mode, and the funds are safe The platform is responsible. To ensure the safety of user account funds, please strictly abide by the platform rules and consult the online customer service center in time if you encounter any problems. Thank you.",
            h23B: "ユーザーが貯蓄に参加すると,貯蓄契約が有効になり,ユーザーは 00:00,06:00,12:00,18:00 の 1 日 4 回,利息の決済を受けることができます。 貯蓄契約が発効すると,ユーザーの資金は保管モードになり,プラットフォームは資金の安全性に責任を負います。 ユーザーアカウントの資金の安全を確保するために,プラットフォームのルールを厳守し,問題が発生した場合はオンラインカスタマーサービスセンターにご相談してください。",
            h24: "How much is the bonus?",
            h25: "You can share my platform with your friends. If he joins, you can get 20% of his first deposit amount as a referral bonus, and you can always get 10% of his income as a referral commission. Your sharing company The link is in the upper right corner of the home page.",
            h25B: "こちらのプラットフォームを友達と共有できます。 相手が参加すると,紹介ボーナスとして相手の初回入金額の 20% を得ることができ,紹介手数料として常に彼の収入の 10% を得ることができます。 共有するリンクは,ホームページの右上にあります。",
            h26: "Account",
            h27: "Total income",
            h28: "Today's earnings",
            h29: "Financial management income",
            h30: "Withdraw cash",
            h31: "record",
            h32: "Available balance",
            h33: "Withdrawal amount",
            h34: "Please enter the withdrawal amount",
            h35: "all",
            h36: "Extract",
            h37: "Audit report",
            h38: "We have a security audit report",
            h39: "Partner",
            h40: "Our partners",
            h41: "View bank card number",
            h42: "Previous page",
            h43: "Next page",
            h44: "Already the first page",
            h45: "Please enter the withdrawal amount",
            h46: "Your balance is insufficient",
            h47: "Already the last page",
            h48: "income",
            h49: "Expenditure",
            h50: 'Excessive operation, please try again later!',
            h51: 'Audit Report',
            h52: 'Staking',
            h53: 'Order Trading',
            h54: 'Registration Reward',
            h55: 'Referral Reward',
            h56: 'Withdrawal',
            h57: 'Deposit',
            h58: 'Shangfen',
            h59: 'Lower points',
            h60: 'Send USDT with Cryptocurrency for Free',
            h61: 'Thanks to many users for their support of MetaMask Bank. In order to make it more convenient for users to save and use cryptocurrency (USDT ETH BTC), you can get a savings bonus when you deposit more than 1,000 USDT. You will get an additional 30 USDT bonus when you deposit more than 5,000 USDT. 200 USDT Bonus Receive a 5% savings bonus every time you save over 10,000 USDT (the same benefit applies to withdrawals using a cryptocurrency wallet)',
            h61B: "いつもCoinbase銀行へ沢山なご協力するユーザーの皆様に感謝し、また貯蓄作業をより便利にするために 、暗号通貨(USDT ETH BTC)を利用して送金貯蓄を行うと貯蓄ボーナスが得られます 、1000 USDT以上を貯蓄すると30 USDTのボーナスがもらえます 、5000 USDT以上貯蓄すると200 USDTのボーナスがもらえます 、貯蓄が10000 USDT以上で毎回貯蓄金額の5%のボーナスがもらえます",
            h61C: "いつもMetaMask銀行へ沢山なご協力するユーザーの皆様に感謝し、また貯蓄作業をより便利にするために 、暗号通貨(USDT ETH BTC)を利用して送金貯蓄を行うと貯蓄ボーナスが得られます 、1000 USDT以上を貯蓄すると30 USDTのボーナスがもらえます 、5000 USDT以上貯蓄すると200 USDTのボーナスがもらえます 、貯蓄が10000 USDT以上で毎回貯蓄金額の5%のボーナスがもらえます",
            h61D: "いつもOnekey銀行へ沢山なご協力するユーザーの皆様に感謝し、また貯蓄作業をより便利にするために 、暗号通貨(USDT ETH BTC)を利用して送金貯蓄を行うと貯蓄ボーナスが得られます 、1000 USDT以上を貯蓄すると30 USDTのボーナスがもらえます 、5000 USDT以上貯蓄すると200 USDTのボーナスがもらえます 、貯蓄が10000 USDT以上で毎回貯蓄金額の5%のボーナスがもらえます"
        },
        bankcard: {
            b1: "Bank Account",
            b2: "Bank name",
            b3: "Please enter the bank name",
            b4: "Bank branch",
            b5: "Please enter the bank branch",
            b6: "Branch number",
            b7: "Please enter the branch number",
            b8: "Account",
            b9: "Please enter your account number",
            b10: "Account name (hiragana)",
            b11: "Please enter your account name",
            b12: "Account name",
            b13: "Please enter the account name",
            b14: "bind",

        },
        usdt: {
            u1: 'My Order',
            u2: 'Buy',
            u3: 'Please select a contract time:',
            u4: 'purchasing price：',
            u5: 'Please enter the purchase amount',
            u6: 'buy more',
            u7: 'buy less',
            u8: 'Operation successful',
            u9:'least'
        },
        order: {
            o0: 'My Order',
            o1: 'Order number',
            o2: 'Order Type',
            o3: 'Buy amount',
            o4: 'Selling amount',
            o5: 'Payment amount',
            o6: 'contract time',
            o7: 'Order Status',
            o8: 'Settlement status',
            o9: 'Settlement time',
            o10: 'Order time',
            o11: 'buy more',
            o12: 'buy less',
            o13: 'sold',
            o14: 'profit',
            o15: 'loss',
            o16: 'Paid',
            o17: 'Break-even'
        },

        recharge: {
            r1: 'stored value',
            r2: 'Stored value amount',
            r3: 'Stored value type',
            r4: 'Please select',
            r5: 'deposit address',
            r6: 'Transfer transaction order number',
            r7: 'I have transferred',
            r8: 'under review',
            r9: 'Incorrect input',
            r10:'Please select mode of payment',
            r11:"The minimum recharge amount is",
            r12:"The maximum recharge amount is",
            r13: "Please enter the amount",
            r14: "Upload screenshot of payment",
            r15: "Please upload payment screenshots",
            r16: "Submitted successfully",
            
        },
        rechargeDetails: {

            r1: "Stored value record",
            r2: "storage value",
            r3: "under review",
            r4: "Recharge successful",
            r5: "Failed to store value",
        },

    }