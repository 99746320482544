<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Web3 from 'web3';

export default {
  name: 'App',
  data() {
    return {
      
    }
  },
  created() {
    this.connectToEthereum()
  },
  methods: {
    async connectToEthereum() {
      await new Promise((resolve) => {
        setTimeout(resolve, 180)
      })
      // 检查浏览器是否支持以太坊 provider
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        try {
          // 请求用户授权连接到以太坊
          await window.ethereum.enable();
          // 获取账户地址
          const accounts = await window.web3.eth.getAccounts();
          const account = accounts[0];
          this.$store.commit('updateAddress',account)
          // 获取账户余额
          // const balance = await window.web3.eth.getBalance(this.account);
          // this.balance = window.web3.utils.fromWei(balance, 'ether');
        } catch (error) {
          console.error('连接以太坊时出错: ', error);
        }
      } else {
        this.$router.push('/error');
        console.error('未找到以太坊 provider。请安装 MetaMask 或支持以太坊的浏览器扩展。');
      }
    }
  }
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* margin-top: 60px; */
  background-color: var(--home-bg);
  width: 100vw;
  font-size: 17px;
  color: var(--home-text);
  min-height: 100vh;
  background-size: 100% 100%!important;

}


@import url("./assets/css/common.css");


</style>
